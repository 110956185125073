<template>
  <Layout>
    <PageHeader :title="'New Event'" />
    <div class="row">
      <div class="col-12">
        <!-- Stepper Start -->
        <div class="card">
          <div class="card-body">
            <form-wizard
              shape="tab"
              color="#5664d2"
              @on-complete="addEvent"
              :finish-button-text="
                selectedInvestorsCount > 0
                  ? 'Create Event & Send Invitations'
                  : 'Create Event'
              "
            >
              <tab-content icon="mdi mdi-calendar">
                <div class="row">
                  <div class="col-12 col-sm-3">
                    <div class="card-body">
                      <div class="met-profile">
                        <div class="met-profile-main">
                          <div class="met-profile-main-pic">
                            <file-upload
                              ref="upload"
                              class="file profile-img bordered"
                              v-model="image"
                              accept="image/png, image/gif, image/jpeg"
                              extensions="gif,jpg,jpeg,png"
                            >
                              <img
                                :src="
                                  newEvent.profileImg
                                    ? $apiDomain + newEvent.profileImg
                                    : require('@/assets/images/no-image.jpg')
                                "
                                alt="Event Image"
                              />
                              <span class="fro-profile_main-pic-change">
                                <i class="fas fa-camera"></i>
                              </span>
                            </file-upload>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-9">
                    <div class="card-body">
                      <div class="form-group mt-2">
                        <label>Event Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Event Name"
                          autocomplete="nope"
                          v-model="newEvent.name"
                          @blur="$v.newEvent.name.$touch()"
                          :class="{
                            'is-invalid': $v.newEvent.name.$error,
                          }"
                        />
                        <div class="invalid-feedback">Required</div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <label for="start-date">Date</label>
                          <date-picker
                            v-model="newEvent.date"
                            type="datetime"
                            format="YYYY-MM-DD hh:mm a"
                            placeholder="Select datetime"
                          ></date-picker>
                        </div>
                        <div class="form-group col-sm-6">
                          <label>Event Firm</label>

                          <div class="col-12">
                            <div class="row">
                              <select
                                class="form-control"
                                v-model="newEvent.firmId"
                              >
                                <option value="null">Please select</option>
                                <option
                                  v-for="(firm, index) in firms"
                                  :value="firm.id"
                                  :key="index"
                                >
                                  {{ firm.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mt-2">
                        <label>Description</label>

                        <ckeditor
                          v-model="newEvent.description"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>
                      </div>

                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <!-- end row -->
              </tab-content>
              <tab-content icon="mdi mdi-account-check">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label for="name">Investors Invitation</label>
                      <div class="col-sm-12 p-0">
                        <vue-good-table
                          :columns="columns"
                          :rows="investors"
                          ref="investorLists"
                          @on-selected-rows-change="setSelectedCount"
                          :select-options="{
                            enabled: true,
                            selectOnCheckboxOnly: false,
                            selectionInfoClass: 'custom-class',
                            selectionText: 'rows selected',
                            clearSelectionText: 'clear',
                            disableSelectInfo: true,
                            selectAllByGroup: true,
                          }"
                        >
                        </vue-good-table>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
            </form-wizard>
          </div>
          <!-- end card-body -->
        </div>
        <!-- Stepper end -->
      </div>
    </div>
  </Layout>
</template>

<script>
/*eslint-disable*/
import { HTTP } from "@/main-source";
import router from "@/router";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { FormWizard, TabContent } from "vue-form-wizard";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "NewInvestor",
  data() {
    return {
      passInputType: "password",
      firms: [],
      investors: [],
      image: [],
      newEvent: {
        name: null,
        date: null,
        profileImg: null,
        firmId: null,
        selectedInvestors: null,
      },
      selectedInvestorsCount: 0,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",

            "|",
            "undo",
            "redo",
          ],
        },
        image: {
          toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        language: "en",
      },
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Fon",
          field: "fon",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
      ],
    };
  },
  validations: {
    newEvent: {
      name: { required },
      date: { required },
    },
  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    ckeditor: CKEditor.component,
  },
  created() {
    this.getInvestorsAndFirms();
  },
  methods: {
    getInvestorsAndFirms: function () {
      this.investors = [];
      let uri = "firms";

      HTTP.get(uri)
        .then((result) => {
          this.firms = result.data.rows;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting firm data",
            showConfirmButton: false,
            timer: 1500,
          });
        });

      uri = "firms?isInvestor=true";
      HTTP.get(uri)
        .then((result) => {
          this.investors = [];

          if (result.data.rows) {
            result.data.rows.forEach((row) => {
              row.users.forEach((user) => {
                this.investors.push({
                  id: user.id,
                  name: user.name,
                  fon: row.name,
                  email: user.email,
                  phone: user.tel,
                });
              });
            });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting investors data",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },

    setSelectedCount: function () {
      this.selectedInvestorsCount = this.$refs["investorLists"]
        ? this.$refs["investorLists"].selectedRows.length
        : 0;
    },
    addEvent: function () {
      this.$v.newEvent.$touch();
      let selectedInvestors = this.$refs["investorLists"].selectedRows;

      if (!this.$v.newEvent.$error) {
        this.newEvent.selectedInvestors = selectedInvestors;
        
        HTTP.post("events", this.newEvent)
          .then((result) => {
            router.push({ name: "events" });
          })
          .catch((error) => {
            console.log("🚀 ~ file: new.vue ~ line 312 ~ error", error);
          });
      }
    },
  },

  watch: {
    image: function () {
      let fd = new FormData();

      fd.append("files", this.image[0].file, this.image[0].name);

      HTTP.post("/files/", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          this.newEvent.profileImg = res.data[0].path;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
